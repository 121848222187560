export default function getFromConfig({ region, method, channel, isAddressSame }) {
  const form2 = ["name", "taxId", "email", "phone"];
  const form3 = ["name", "state", "city", "postalCode", "street", "no", "email", "phone"];

  //  common for all countries
  const formConfig = {
    "CreditCard-OpenPay": ["name", "cardNumber", "expYear", "expMonth", "cvc", "email", "phone"],
    "CreditCard-E-Comprocessing": ["name", "cardNumber", "expYear", "expMonth", "cvc", "email", "phone"],
    "CreditCard-CrediMax": ["name", "cardNumber", "expYear", "expMonth", "cvc", "email", "phone"],
    "CreditCard-CardPay": ["name", "cardNumber", "expYear", "expMonth", "cvc", "email", "phone"],
    "CreditCard-Monnet": form2,
    "CreditCard-Paypal": ["email", "phone"],
    "CreditCard-Xendit": ["firstName", "lastName", "email", "phone", "cardNumber", "expYear", "expMonth", "cvc"],
    "CreditCard-QIWI": ["name", "email", "phone"],
    "Wallet-Todito": [...form2, "todito_cardNo", "todito_pin"],
    "Wallet-AlipayHK": ["name", "email", "phone"],
    "Wallet-AlipayCN": ["name", "email", "phone"],
    "Wallet-GCash": ["name", "email", "phone"],
    "Wallet-Paymaya": ["name", "email", "phone"],
    "Wallet-GrabPay": ["name", "email", "phone"],
    "Wallet-PayNow": ["name", "email", "phone"],
    "Wallet-RabbitLinePay": ["name", "email", "phone"],
    "Wallet-BPI": ["name", "email", "phone"],
    "Wallet-MADA": ["name", "email", "phone"],
    "Wallet-KNet": ["name", "email", "phone"],
    "Wallet-QIWI": ["name", "email", "phone"],
    "CoDi-STP": ["name", "email", "phone"],
    "CreditCard-SHAREitPay": ["name", "email", "phone"],
    "GCash-SHAREitPay": ["name", "email", "phone"],
    "UPI-SHAREitPay": ["name", "email", "phone"],
    "NetBanking-SHAREitPay": ["name", "email", "phone"],
    "PiPay-PiPay": ["name", "email", "phone"],
    "PayGo-EDEE": ["name", "email", "phone"],
    "AlfaClick-Payssion": ["name", "email", "phone"],
    "Qiwi-Payssion": ["name", "email", "phone"],
    "WebMoney-Payssion": ["name", "email", "phone"],
    "YandexMoney-Payssion": ["name", "email", "phone"],
    "UnionPay-Payssion": ["name", "email", "phone"],
    "Wallet-YooMoney": ["name", "email", "phone"],
    "DirectDebit-Xendit": ["firstName", "lastName", "email", "phone"],
    "OTC-Xendit": ["firstName", "lastName", "email", "phone"],
    "Wallet-GcashXendit": ["name", "email", "phone"],
    "Wallet-KakaoPayAlipay": ["name", "email", "phone"],
    "Wallet-DANA": ["name", "email", "phone"],
    "AlipayPlus-AlipayPlus": ["name", "email", "phone"],
    "Wallet-TNG": ["name", "email", "phone"],
    "Wallet-BOOST": ["name", "email", "phone"],
    "Wallet-TrueMoney": ["name", "email", "phone"],
    "Wallet-Vita": form2,
    "Wallet-Chek": form2,
    "Wallet-Mach": form2,
    "Wallet-TPaga": form2,
    "Wallet-PicPay": form2,
    "Wallet-AME": form2,
    "Wallet-Paypal": form2,
    "Wallet-Eprepag": ["email"],
    "Wallet-MOL": form2,
    "Wallet-Paysafecard": ["email"],
    "Wallet-Skrill": ["email"],
    "Wallet-KakaoPay": ["name", "email", "phone"],
    "Wallet-TOSS": ["name", "email", "phone"],
    "Wallet-PAYCO": ["name", "email", "phone"],
    "Wallet-SamsungPay": ["name", "email", "phone"],
    "CreditCard-Payletter": ["name", "email", "phone"],
    "BankTransfer-Payletter": ["name", "email", "phone"],
    "VirtualAccount-Payletter": ["name", "email", "phone"],
    "Boleto-BS2": [...form2, "boleto_cep"],
    "BoletoRapido-BS2": [...form2, "boleto_cep"],
    "Lottery-Caixa": [...form2, "boleto_cep"],
    "PIX-BS2": form2,
    "PIX-Bradesco": form2,
    "PIX-Rendimento": form2,
    "PlayersBank-BS2": form2,
    "BankTransfer-SafetyPay": form2,
    "BankTransfer-ETpay": form2,
    "Cash-SafetyPay": ["name", "email", "phone"],
    "Khipu-Khipu": ["name", "email"],
    "CreditCard-Payku": form2,
    "Pago46-Payku": ["email"],
    "KLAP-Multicaja": form2,
    "Cash-Multicaja": form2,
    "BankTransfer-Multicaja": form2,
    "PSE-Payty": form2,
    "PSE-PayValida": form2,
    "Efecty-Payty": form2,
    "Efecty-PaytyV2": form2,
    "PSE-PaytyV2": form2,
    "PSE-ACH": [...form2, "address", "bank_list"],
    "SPEI-OpenPay": ["name", "email", "phone"],
    "SPEI-STP": ["name", "email", "phone"],
    "SPEI-Banwire": ["name", "email", "phone"],
    "SPEI-OPM": ["name", "email", "phone"],
    "OXXOPay-Conekta": ["name", "email", "phone"],
    "OXXO-Banwire": ["name", "email", "phone"],
    "OXXO-CardPay": ["name", "email", "phone"],
    "OXXO-Localpayment": ["name", "email", "phone"],
    "Cash-OpenPay": form3,
    "Cash-Banwire": form3,
    "Baloto-PayValida": form2,
    "SuRed-PayValida": form2,
    "GANA-PayValida": form2,
    "Bancolombia-PayValida": form2,
    "Wallet-Daviplata": [...form2, "address"],
    "Wallet-Rappipay": [...form2, "address"],
    "Wallet-Dale": [...form2, "address"],
    "Wallet-Movii": [...form2, "address"],
    "Wallet-Nequi": [...form2, "address"],
    "Baloto-IGT": form2,
    "DepositExpress-Levpay": form2,
    "BankTransfer-PagoEfectivo": form2,
    "PagoEfectivo-PagoEfectivo": form2,
    "Cash-PagoEfectivo": form2,
    "Cash-PayCash": ["name", "email", "phone"],
    "Wallet-Fawry": ["name", "email", "phone"],
    "Wallet-PaypalXCG": ["email"],
    "PagoFacil-Localpayment": form2,
    "Rapipago-Localpayment": form2,
    "BankTransfer-Localpayment": [...form2, "account_number", "bank_list"],
    "Cash-Localpayment": [...form2, "bank_list"],
    "CreditCard-Localpayment": [...form2, "cardNumber", "expYear", "expMonth", "cvc"],
    "DebitCard-Localpayment": [...form2, "cardNumber", "expYear", "expMonth", "cvc"],
    "CreditCard-Movii": ["name", "cardNumber", "expYear", "expMonth", "cvc", "email", "phone"],
    "CreditCard-Izipay": ["name", "cardNumber", "expYear", "expMonth", "cvc", "email", "phone"],
    "Sofort-Emerchantpay": ["firstName", "lastName", "email", "phone"],
    "Trustly-Emerchantpay": ["firstName", "lastName", "email", "phone"],
    "Neosurf-Emerchantpay": ["firstName", "lastName", "email", "phone"],
    "Bancontact-Emerchantpay": ["firstName", "lastName", "email", "phone"],
    "EPS-Emerchantpay": ["firstName", "lastName", "email", "phone"],
    "Giropay-Emerchantpay": ["firstName", "lastName", "email", "phone"],
    "iDeal-Emerchantpay": ["firstName", "lastName", "email", "phone"],
    "MyBank-Emerchantpay": ["firstName", "lastName", "email", "phone"],
    "P24-Emerchantpay": ["firstName", "lastName", "email", "phone"],
    "BankTransfer-Monnet": form2,
    "Cash-Monnet": form2,
    "BNCR-PayValida": ["name", "email", "phone"],
    "BNCR-PayCash": ["name", "email", "phone"],
    "OpenFinance-Iniciador": ["name", "taxId", "email", "bank_list"],
    "Wallet-Tupay": ["firstName", "lastName", "email", "phone", "taxId"],
    "Crypto-TripleA": ["email"],
    "WebPay-Payku": form2,
  };

  const formConfig2 = {
    "PER-CreditCard-OpenPay": ["name", "cardNumber", "expYear", "expMonth", "cvc", "email", "phone"],
    "BRA-CreditCard-Paypal": ["taxId", "email", "phone"],
    "BRA-RecurringCreditCard-Paypal": ["taxId", "email", "phone"],
  };

  const addressNeededFor = [
    "CreditCard-CardPay",
    "CreditCard-Paypal",
    "CreditCard-OpenPay",
    "CreditCard-E-Comprocessing",
    "RecurringCreditCard-Paypal",
    "CreditCard-CrediMax",
    "CreditCard-Xendit",
    "CreditCard-Localpayment",
    "DebitCard-Localpayment",
    "CreditCard-Movii",
    "Sofort-Emerchantpay",
    "Trustly-Emerchantpay",
    "Neosurf-Emerchantpay",
    "Bancontact-Emerchantpay",
    "EPS-Emerchantpay",
    "Giropay-Emerchantpay",
    "iDeal-Emerchantpay",
    "MyBank-Emerchantpay",
    "P24-Emerchantpay",
    "CreditCard-Izipay",
    "CreditCard-CrediMax",
  ];

  let config = formConfig2[region + "-" + method + "-" + channel] || formConfig[method + "-" + channel];

  if (addressNeededFor.includes(method + "-" + channel)) {
    if (isAddressSame) {
      config = [...config, "bill_country", "bill_state", "bill_city", "bill_street", "bill_no", "bill_postalCode"];
    } else {
      config = [
        ...config,
        "bill_country",
        "bill_state",
        "bill_city",
        "bill_street",
        "bill_no",
        "bill_postalCode",
        "delivery_country",
        "delivery_state",
        "delivery_city",
        "delivery_street",
        "delivery_no",
        "delivery_postalCode",
      ];
    }
  }

  return config;
}
