import React, { useState, useEffect } from "react";
import "./PaymentMethodMobile.scss";
import Channel from "./Channel";
import { connect } from "react-redux";
import { changeMethod, setWalletChannels, setPaymentInfo } from "../redux/actions";
import Radio from "@material-ui/core/Radio";
import RightArrowBlack from "../img/right_arrow_black.png";
import { channelConfig, methodConfig } from "../utils/configurations";
import ChannelSelect from "./Peru/ChannelSelect";
import { useTranslation } from "react-i18next";
import Name from "./form-input/Name";
import TaxId from "./form-input/TaxId";
import Email from "./form-input/Email";
import CreditCardExpTime from "./form-input/CreditCardExpTime";
import CreditCardNumber from "./form-input/CreditCardNumber";
import State from "./form-input/State";
import City from "./form-input/City";
import PostalCode from "./form-input/PostalCode";
import StreetAndNumber from "./form-input/StreetAndNumber";
import HiddenForm from "./form-input/Openpay-creditcard/HiddenForm";
import CardNo from "./form-input/todito/CardNo";
import PinNo from "./form-input/todito/PinNo";
import Installment from "./form-input/Installment";
import MachCardNo from "./form-input/Mach/MachCardNo";
import PaypalCreditcard from "./form-input/Paypal-creditcard/PaypalCreditcard";
import Source from "./form-input/Source";
import Cep from "./form-input/Boleto/Cep";
import FirstName from "./form-input/FirstName";
import LastName from "./form-input/LastName";
// import PipayForm from "./form-input/Pipay/PipayForm";
// import PaygoOtp from "./form-input/Paygo/PaygoOtp";
import Checkbox from "@material-ui/core/Checkbox";
import FlipMove from "react-flip-move";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MobilePhone from "./form-input/MobilePhone";
import Address from "./form-input/Address/Address";
import BankList from "./form-input/Localpayment/BankList";
import AccountNumber from "./form-input/Localpayment/AccountNumber";
import VisaCard from "../img/visa_card.png";
import MasterCard from "../img/master_card.png";
import Amex from "../img/amex.png";
import AddressBox from "./form-input/AddressBox";
import BankListPopUp from "./form-input/BankListPopUp";
import CircularProgress from "@material-ui/core/CircularProgress";
import CrediMax from "./form-input/CrediMax/CrediMax";
import SubmitForm from "./form-input/SubmitPay/SubmitForm";
var classNames = require("classnames");
var Scroll = require("react-scroll");
var Element = Scroll.Element;
var scroller = Scroll.scroller;

function PaymentMethod({
  image,
  imageMobile,
  text,
  type,
  channel,
  loading = () => {},
  form,
  setForm,
  setFocusOnInput,
  paypalChildRefMobile,
  pipayData,
  crediMaxChildRef,
  payRequest,
  isReadyToPay,
  submitData,
  paygoOtp,
  isChecked,
  setIsChecked,
  setShowPolicy,
  handleError,
  setSelectNotice,
  ...props
}) {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const isPeru = () => {
    return type === "Cash" && channel === "SafetyPay" && props.tradeInfo?.app_info?.region === "PER";
  };

  const handleChange = () => {
    if (type === "Wallet") {
      props.setPaymentInfo("");
      props.setWalletChannels(type, handleError, loading);
    } else {
      props.changeMethod(type, channel, loading, handleError);
    }
  };

  useEffect(() => {
    //console.log("method changed");
    if (props.method === type) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [props.method]);

  return (
    <>
      <Element name={type}>
        <div className="methodMobile" id={type}>
          <div
            className="methodMobile__methodTitle"
            onClick={() => {
              if (props.method !== type) {
                handleChange();
              } else {
                setIsActive((prev) => !prev);
              }
            }}
          >
            <FormControlLabel
              value="other"
              control={
                <Radio
                  disabled
                  checked={props.method === type}
                  //value={type}
                />
              }
              label={text}
            />
            <div className="methodMobile__methodTitle__arrowLogo">
              <img className="methodMobile__methodTitle__logo" src={imageMobile} alt="" />
              {channelConfig(type, channel) && (
                <img
                  className={classNames("methodMobile__methodTitle__arrow", {
                    arrow__0: !isActive,
                    arrow__90: isActive,
                  })}
                  src={RightArrowBlack}
                  alt=""
                />
              )}
            </div>
          </div>
          {type === "CreditCard" && props.payChannel === "CrediMax" && !props.loadedLibs?.["CreditCard-CrediMax"] && (
            <div style={{ textAlign: "center", paddingBottom: "15px" }}>
              <CircularProgress size={24} style={{ color: "var(--pagsmile--color)" }} />
            </div>
          )}
          {props.loadedLibs?.["CreditCard-CrediMax"] &&
            type === "CreditCard" &&
            channel === "CrediMax" &&
            window.innerWidth < 800 && (
              <>
                {type === "CreditCard" &&
                  channel === "CrediMax" &&
                  props.method === "CreditCard" &&
                  props.payChannel === "CrediMax" &&
                  isActive && <p className="methodMobile__filling__title">{t("filling_details")}</p>}

                <CrediMax
                  style={{
                    display:
                      type === "CreditCard" &&
                      channel === "CrediMax" &&
                      props.method === "CreditCard" &&
                      props.payChannel === "CrediMax" &&
                      isActive
                        ? "block"
                        : "none",
                    marginTop: "20px",
                  }}
                  ref={crediMaxChildRef}
                  payRequest={payRequest}
                  setIsLoading={loading}
                  form={form}
                  setForm={setForm}
                  isReadyToPay={isReadyToPay}
                  handleError={handleError}
                />
              </>
            )}
          <FlipMove
            enterAnimation="accordionVertical"
            leaveAnimation="none"
            duration={400}
            delay={200}
            onStart={() => {
              scroller.scrollTo(type, {
                duration: 500,
                smooth: true,
                offset: -100, // Scrolls to element + 50 pixels down the page
              });
            }}
          >
            {props.method === type && isActive && (
              <div className="methodMobile__content">
                {channelConfig(type, channel) && (
                  <div className="methodMobile__channel__content">
                    {isPeru() ? (
                      <>
                        <ChannelSelect
                          channelList={props.channelList}
                          method={props.method}
                          loading={loading}
                          handleError={handleError}
                        />
                      </>
                    ) : (
                      Array.isArray(props.channelList[props.method]) &&
                      props.channelList[props.method]?.map((item) => {
                        return <Channel data={item} key={item.key} loading={loading} handleError={handleError} />;
                      })
                    )}
                  </div>
                )}
                {/* promotions and notices */}
                {props.method === "Cash" &&
                  props.payChannel === "PayCash" &&
                  props.tradeInfo?.app_info?.region === "ECU" && (
                    <p className="methodMobile__channelPromotion__notice">
                      {t("pay_points1")}
                      <a
                        href="https://checkout.pagsmile.com/public/pagsmile-files/puntos-Ecuador.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t("pay_points2")}
                      </a>
                      {t("pay_points3")}
                    </p>
                  )}
                <Element name="filling_detail">
                  <div className="methodMobile__content__filling">
                    {!["CreditCard-CrediMax", "CreditCard-Mercadopago"].includes(`${type}-${channel}`) && (
                      <p className="methodMobile__filling__title">{t("filling_details")}</p>
                    )}
                    <div className="methodMobile__content__filling__content">
                      {type === "CreditCard" && props.payChannel === "CrediMax" && window.innerWidth < 800 && (
                        <>
                          <MobilePhone form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                          <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                          <Address form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                        </>
                      )}

                      {type === "CreditCard" && props.payChannel === "CardPay" && (
                        <>
                          <Name
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                            creditcard={true}
                          />

                          <CreditCardNumber
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <CreditCardExpTime form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />

                          <State
                            form={form}
                            setForm={setForm}
                            setFocusOnInput={setFocusOnInput}
                            tradeInfo={props.tradeInfo}
                          />
                          <City
                            form={form}
                            setForm={setForm}
                            setFocusOnInput={setFocusOnInput}
                            tradeInfo={props.tradeInfo}
                          />

                          <PostalCode form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                          <StreetAndNumber form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />

                          <MobilePhone
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <Email
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                        </>
                      )}

                      {type === "CreditCard" && props.payChannel === "OpenPay" && (
                        <>
                          <Name
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            creditcard={true}
                            setFocusOnInput={setFocusOnInput}
                          />
                          {/* <Installment form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} /> */}

                          <CreditCardNumber
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <CreditCardExpTime form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />

                          <MobilePhone
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <Email
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <Address form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                          <HiddenForm form={form} />
                        </>
                      )}

                      {type === "CreditCard" &&
                        props?.tradeInfo?.app_info?.region === "BRA" &&
                        props.paypalCreditCard.authorizationToken && (
                          <>
                            <PaypalCreditcard
                              authorizationToken={props.paypalCreditCard.authorizationToken}
                              loading={loading}
                              handleError={handleError}
                              ref={paypalChildRefMobile}
                              target="mobile"
                              setFocusOnInput={setFocusOnInput}
                            />

                            <Installment form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                            <TaxId
                              form={form}
                              setForm={setForm}
                              tradeInfo={props.tradeInfo}
                              setFocusOnInput={setFocusOnInput}
                            />

                            <Email
                              form={form}
                              setForm={setForm}
                              tradeInfo={props.tradeInfo}
                              setFocusOnInput={setFocusOnInput}
                            />
                            <MobilePhone
                              form={form}
                              setForm={setForm}
                              tradeInfo={props.tradeInfo}
                              setFocusOnInput={setFocusOnInput}
                            />
                            <Address
                              form={form}
                              setForm={setForm}
                              setSelectNotice={setSelectNotice}
                              setFocusOnInput={setFocusOnInput}
                            />
                          </>
                        )}

                      {((type === "CreditCard" && props.payChannel === "Localpayment") ||
                        (type === "DebitCard" && props.payChannel === "Localpayment")) && (
                        <>
                          <div style={{ marginBottom: "15px", display: "flex", alignItems: "center" }}>
                            <img src={VisaCard} alt="" />
                            <img src={MasterCard} alt="" />
                            {type === "CreditCard" && props.payChannel === "Localpayment" && <img src={Amex} alt="" />}
                          </div>

                          <Name
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            creditcard={true}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <CreditCardNumber
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />

                          <CreditCardExpTime form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                          <TaxId
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />

                          <Email
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <MobilePhone
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />

                          <Address
                            form={form}
                            setForm={setForm}
                            setSelectNotice={setSelectNotice}
                            setFocusOnInput={setFocusOnInput}
                          />
                        </>
                      )}

                      {type === "CreditCard" && props?.tradeInfo?.app_info?.region === "PHL" && (
                        <>
                          <CreditCardNumber
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <CreditCardExpTime form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />

                          <Installment form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                          <Email
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />

                          <FirstName form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                          <LastName form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />

                          <MobilePhone
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                        </>
                      )}
                      {type === "Wallet" && props.payChannel === "Todito" && (
                        <>
                          <Name
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <TaxId
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />

                          <Email
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <MobilePhone
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />

                          <p className="home--fillTitle">{t("todito_notice")}</p>

                          <CardNo form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                          <PinNo form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                        </>
                      )}

                      {type === "Cash" && (props.payChannel === "Banwire" || props.payChannel === "OpenPay") && (
                        <>
                          <Name
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />

                          <MobilePhone
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <Email
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />

                          <State
                            form={form}
                            setForm={setForm}
                            setFocusOnInput={setFocusOnInput}
                            tradeInfo={props.tradeInfo}
                          />
                          <City
                            form={form}
                            setForm={setForm}
                            setFocusOnInput={setFocusOnInput}
                            tradeInfo={props.tradeInfo}
                          />

                          <PostalCode form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                          <StreetAndNumber form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                        </>
                      )}
                      {((type === "BankTransfer" && props.payChannel === "SafetyPay") ||
                        ((type === "PSE" || type === "Efecty") && props.payChannel === "Payty") ||
                        ((type === "Efecty" || type === "PSE") && props.payChannel === "PaytyV2") ||
                        (type === "PIX" && props.payChannel === "BS2") ||
                        (type === "Wallet" &&
                          (props.payChannel === "PicPay" ||
                            props.payChannel === "Paypal" ||
                            props.payChannel === "AME" ||
                            props.payChannel === "TPaga" ||
                            props.payChannel === "Mach" ||
                            props.payChannel === "Vita" ||
                            props.payChannel === "Chek")) ||
                        (type === "DepositExpress" && props.payChannel === "Levpay") ||
                        ((type === "PagoEfectivo" || type === "BankTransfer" || type === "Cash") &&
                          props.payChannel === "PagoEfectivo") ||
                        (type === "KLAP" && props.payChannel === "Multicaja") ||
                        (type === "Cash" && props.payChannel === "Multicaja") ||
                        (type === "BankTransfer" && props.payChannel === "Multicaja") ||
                        (type === "BankTransfer" && props.payChannel === "ETpay") ||
                        (type === "CreditCard" && props.payChannel === "Monnet") ||
                        (type === "CreditCard" && props.payChannel === "Payku") ||
                        (type === "PagoFacil" && props.payChannel === "Localpayment") ||
                        (type === "Rapipago" && props.payChannel === "Localpayment") ||
                        (type === "Bancolombia" && props.payChannel === "PayValida") ||
                        (type === "Baloto" && (props.payChannel === "PayValida" || props.payChannel === "IGT")) ||
                        (type === "SuRed" && props.payChannel === "PayValida") ||
                        (type === "GANA" && props.payChannel === "PayValida") ||
                        (type === "WebPay" && props.payChannel === "Payku") ||
                        ((type === "BankTransfer" || type === "Cash") && props.payChannel === "Monnet")) && (
                        <>
                          <Name
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                            mobile
                          />
                          <TaxId
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                            mobile
                          />

                          <Email
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                            mobile
                          />
                          <MobilePhone
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                            mobile
                          />
                        </>
                      )}

                      {type === "Pago46" && props.payChannel === "Payku" && (
                        <>
                          <Email
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                            mobile
                          />
                        </>
                      )}

                      {type === "Khipu" && props.payChannel === "Khipu" && (
                        <>
                          <Name
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                            mobile
                          />
                          <Email
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                            mobile
                          />
                        </>
                      )}

                      {type === "PSE" && props.payChannel === "ACH" && (
                        <>
                          <BankListPopUp form={form} setForm={setForm} />
                          <Name
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <TaxId
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />

                          <Email
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <MobilePhone
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />

                          <AddressBox
                            form={form}
                            setForm={setForm}
                            setFocusOnInput={setFocusOnInput}
                            tradeInfo={props.tradeInfo}
                          />
                        </>
                      )}

                      {type === "Crypto" && props.payChannel === "TripleA" && (
                        <>
                          <Email
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                        </>
                      )}
                      {type === "BankTransfer" && props.payChannel === "Localpayment" && (
                        <>
                          <Name
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <BankList form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                          <AccountNumber
                            form={form}
                            setForm={setForm}
                            region={props.tradeInfo?.app_info?.region}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <TaxId
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />

                          <Email
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <MobilePhone
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                        </>
                      )}
                      {type === "Cash" && props.payChannel === "Localpayment" && (
                        <>
                          <Name
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <BankList form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                          <TaxId
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />

                          <Email
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <MobilePhone
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                        </>
                      )}
                      {type === "OpenFinance" && props.payChannel === "Iniciador" && (
                        <>
                          <BankList
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <Name
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />

                          <TaxId
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <Email
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                        </>
                      )}
                      {(((type === "Boleto" || type === "BoletoRapido") && props.payChannel === "BS2") ||
                        (type === "Lottery" && props.payChannel === "Caixa")) && (
                        <>
                          <Name
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />

                          <TaxId
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <Cep form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />

                          <Email
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <MobilePhone
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                        </>
                      )}
                      {((type === "Wallet" &&
                        (props.payChannel === "AlipayCN" ||
                          props.payChannel === "AlipayHK" ||
                          props.payChannel === "GCash" ||
                          props.payChannel === "KakaoPay" ||
                          props.payChannel === "DANA" ||
                          props.payChannel === "TNG" ||
                          props.payChannel === "TrueMoney" ||
                          props.payChannel === "GcashXendit" ||
                          props.payChannel === "Paymaya" ||
                          props.payChannel === "PayNow" ||
                          props.payChannel === "GrabPay" ||
                          props.payChannel === "MADA" ||
                          props.payChannel === "KNet" ||
                          props.payChannel === "YooMoney")) ||
                        ((type === "GCash" || type === "CreditCard" || type === "NetBanking" || type === "UPI") &&
                          props.payChannel === "SHAREitPay") ||
                        ((type === "AlfaClick" || type === "Qiwi" || type === "WebMoney" || type === "YandexMoney") &&
                          props.payChannel === "Payssion") ||
                        (type === "Cash" && props.payChannel === "SafetyPay") ||
                        (type === "OXXO" &&
                          (props.payChannel === "Banwire" ||
                            props.payChannel === "CardPay" ||
                            props.payChannel === "Localpayment")) ||
                        (type === "SPEI" &&
                          (props.payChannel === "OpenPay" ||
                            props.payChannel === "STP" ||
                            props.payChannel === "Banwire" ||
                            props.payChannel === "OPM")) ||
                        (type === "CoDi" && props.payChannel === "STP") ||
                        (type === "OXXOPay" && props.payChannel === "Conekta") ||
                        (type === "BNCR" && props.payChannel === "PayValida") ||
                        (type === "BNCR" && props.payChannel === "PayCash") ||
                        (type === "Cash" && props.payChannel === "PayCash")) && (
                        <>
                          <Name
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <Email
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <MobilePhone
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                        </>
                      )}
                      {/* {type === "PiPay" && props.payChannel === "PiPay" && (
                        <>
                          <Name
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <Email
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <MobilePhone
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          {pipayData && <PipayForm target="mobile" data={pipayData} />}
                        </>
                      )} */}
                      {/* {type === "PayGo" && props.payChannel === "EDEE" && (
                        <>
                          <Name form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                          <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                          <MobilePhone form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                          {paygoOtp && <PaygoOtp phone={form.phone.value} setIsLoading={loading} />}
                        </>
                      )} */}
                      {((type === "DirectDebit" && props.payChannel === "Xendit") ||
                        (type === "OTC" && props.payChannel === "Xendit")) && (
                        <>
                          <FirstName form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                          <LastName form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />

                          <Email
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <MobilePhone
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                        </>
                      )}
                      {type === "Wallet" && props.payChannel === "Tupay" && (
                        <>
                          <FirstName form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                          <LastName form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                          <TaxId form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                          <Email form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                          <MobilePhone form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                        </>
                      )}
                      {type === "CreditCard" && props.payChannel === "Movii" && (
                        <>
                          <Name
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                            creditcard={true}
                          />

                          <CreditCardNumber
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <CreditCardExpTime form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />

                          <MobilePhone
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <Email
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <Address form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                        </>
                      )}

                      {type === "Wallet" &&
                        (props.payChannel === "Daviplata" ||
                          props.payChannel === "Rappipay" ||
                          props.payChannel === "Dale" ||
                          props.payChannel === "Movii" ||
                          props.payChannel === "Nequi") && (
                          <>
                            <Name
                              form={form}
                              setForm={setForm}
                              tradeInfo={props.tradeInfo}
                              setFocusOnInput={setFocusOnInput}
                            />
                            <TaxId
                              form={form}
                              setForm={setForm}
                              tradeInfo={props.tradeInfo}
                              setFocusOnInput={setFocusOnInput}
                            />
                            <Email
                              form={form}
                              setForm={setForm}
                              tradeInfo={props.tradeInfo}
                              setFocusOnInput={setFocusOnInput}
                            />
                            <MobilePhone
                              form={form}
                              setForm={setForm}
                              tradeInfo={props.tradeInfo}
                              setFocusOnInput={setFocusOnInput}
                            />
                            <AddressBox
                              form={form}
                              setForm={setForm}
                              setFocusOnInput={setFocusOnInput}
                              tradeInfo={props.tradeInfo}
                            />
                          </>
                        )}
                      {props.tradeInfo?.checkout_url && props.method && props.payChannel && (
                        <Source form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} mobile />
                      )}
                    </div>
                    <div className="methodMobile__content___bottom">
                      <div className="methodMobile__content___checkboxContainer">
                        <Checkbox
                          size="small"
                          checked={isChecked}
                          onChange={(event) => {
                            setIsChecked(event.target.checked);
                          }}
                        />
                        <span className="methodMobile__content___privacyText1">
                          {t("privacy_policy1")}
                          <span
                            className="methodMobile__content___privacyText2"
                            onClick={() => {
                              setShowPolicy(true);
                            }}
                          >
                            {t("privacy_policy2")}
                          </span>
                          {t("privacy_policy3")}
                        </span>
                      </div>
                      <p className="home--error" style={{ display: isChecked ? "none" : "block" }}>
                        {t("privacy_policy_error")}
                      </p>
                    </div>
                  </div>
                </Element>
              </div>
            )}
          </FlipMove>
        </div>
      </Element>
    </>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    method: state.method,
    payChannel: state.payChannel,
    channelList: state.channelList,
    tradeInfo: state.tradeInfo,
    paypalCreditCard: state.paypalCreditCard,
    loadedLibs: state.loadedLibs,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeMethod: (method, channel, setIsLoading, handleError) =>
      dispatch(changeMethod(method, channel, setIsLoading, handleError)),
    setWalletChannels: (method, handleError, loading) => dispatch(setWalletChannels(method, handleError, loading)),
    setPaymentInfo: (item) => dispatch(setPaymentInfo(item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethod);
